import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import map from '../Images/usMap.png'
import _404 from './_404';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function SchoolCollegeUniversityDetails() {
    const parameter1 = useParams();
    parameter1.parameter2 = parameter1.parameter2.replace(/-/g, ' ').trim();
    //console.log(parameter1.parameter2);

    const [count, setcount] = useState(0);
    const [progress, setProgress] = useState(50);
    const [universitydetails, setuniversitydetails] = useState([]);
    const [universitydetailserr, setUniversitydetailserr] = useState(0);
    const [lstuniversity_degree, setuniversity_degree] = useState([]);
    const [relateduniversities, setrelateduniversities] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(Base_API_URL + "/api/Home/GetUniversitiesDetails?UniName=" + parameter1.parameter2)
            .then(response => {
                if (response.data.length > 0) {
                    setuniversitydetails(response.data)
                    setProgress(100)
                } else {
                    setUniversitydetailserr(1)
                    setProgress(100)
                }
            })
            .catch(err => {
                //console.log(err);
                setUniversitydetailserr(2)
                setProgress(100)
            })

        axios.get(Base_API_URL + "/api/Home/GetDegreeDetails?Name=" + parameter1.parameter2)
            .then(response => setuniversity_degree(response.data))
            .catch(err => {
                //console.log(err);
            })

        axios.get(Base_API_URL + "/api/SchoolnDegree/GetRelatedUniversities?Name=" + parameter1.parameter2)
            .then(response => setrelateduniversities(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, [count]);
    //console.log(universitydetails);
    //console.log(lstuniversity_degree);

    var academic_structure = [];
    if (universitydetails.length > 0) {
        academic_structure = universitydetails[0].programs.split("/");
        academic_structure = academic_structure.filter(function (e) { return e.replace(/(\r\n|\n|\r)/gm, "") }).slice(2);
        //console.log(academic_structure);
    }

    var lstunidegree = [];
    if (lstuniversity_degree.length > 0) {
        lstuniversity_degree.map(item => (
            lstunidegree.push(item.degreeDetail.split(',').filter((i) => i != " "))
        ))
        //console.log(lstunidegree);
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='#C29A81' height={5} progress={progress} />
            <Helmet>
                <title>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} In USA {year} - Profile, Rankings and Data | US Best Schools, Colleges & Universities</title>
                <meta name='description' content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }) + ` ` + year + ` United States ranking & review including accreditation, study areas, degree levels, tuition range, admission rate, facilities, services and official social media.`} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="d-md-block d-none"><br /><br /><br /><br /></div>
            <div className="d-md-none d-block"><br /></div>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item><NavLink to='/'>Home</NavLink></Breadcrumb.Item>
                    <Breadcrumb.Item style={{ textTransform: 'capitalize' }}><NavLink to={'/' + parameter1.parameter1 + '/'}>{parameter1.parameter1.replace(/-/g, ' ')}</NavLink></Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ textTransform: 'capitalize' }}>{parameter1.parameter2.replace(/-/g, ' ')}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            {

                universitydetailserr == 0 ?
                    universitydetails.length > 0 ?
                        <>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="card" style={{ backgroundColor: '#B8DDE3' }}>
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-12 col-md-2 text-md-left text-center mt-auto mb-auto">
                                                        <LazyLoadImage effect="blur" style={{ width: '70px', borderRadius: '50px' }} src={universitydetails[0].image} />
                                                    </div>
                                                    <div className="col-12 col-md-8 text-center mt-auto mb-auto">
                                                        <label><b>Rank: </b>{universitydetails[0].rank}</label>
                                                        <h3 className='font-weight-bold'>{universitydetails[0].uniName}</h3>
                                                    </div>
                                                    <div className="col-12 col-md-2 text-center mt-auto mb-auto">
                                                        <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '100%', borderRadius: '50px', color: 'white' }}>Request Info</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-md-0 mb-4">
                                        <div className="card">
                                            <h2 className="card-header font-weight-bold text-center" style={{ backgroundColor: '#B8DDE3', color: 'black' }}>University Location</h2>
                                            <div className="row">
                                                <div className="col-12">
                                                    <LazyLoadImage effect="blur" src={map} className="card-img-fluid w-100" alt="US-Map" />
                                                </div>
                                                <div className="col-12 mt-auto mb-auto">
                                                    <div className="card-body text-center font-weight-bold border-bottom">
                                                        <p className="card-text font-weight-normal"><b><i className="fa fa-map-marker" aria-hidden="true" style={{ color: 'red' }}></i> </b>{universitydetails[0].address}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 border-right">
                                                    <div className="card-body text-center font-weight-bold">
                                                        <p className="card-text"><b></b> <a href={'tel:' + universitydetails[0].tel} data-tel={universitydetails[0].tel}><i className="fa fa-phone" aria-hidden="true" style={{ color: 'blue' }}></i> {universitydetails[0].tel}</a></p>
                                                    </div>
                                                </div>
                                                <div className="col-6 border-left">
                                                    <div className="card-body text-center font-weight-bold">
                                                        <p className="card-text"><b><i className="fa fa-fax" aria-hidden="true" style={{ color: 'blue' }}></i></b>{universitydetails[0].fax}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="csn-widget border-bottom" data-designation="ground" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="search" data-header-background="#2e5058" data-header-text-color="light" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '377px' }} src="https://widget.careerschoolnow.org?type=search&amp;designation=ground&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=2e5058&amp;header_text_color=light&amp;href=https%3A%2F%2Fwww.schoolndegree.com%2Fforms%2F" id="iFrameResizer0"></iframe></div></div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h2 className="card-header font-weight-bold text-center text-md-left" style={{ backgroundColor: '#B8DDE3', color: 'black' }}>University Snapshot</h2>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Control</b></p>
                                                        <p><i className="fa fa-building fa-3x" style={{ color: '#B8DDE3' }} aria-hidden="true"></i></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].control}</p>
                                                    </div>
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Selectivity</b></p>
                                                        <p><i className="fa fa-question-circle fa-3x" style={{ color: '#B8DDE3' }} aria-hidden="true"></i></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].selectivity}</p>
                                                    </div>
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Entity</b></p>
                                                        <p><i className="fa fa-usd fa-3x" style={{ color: '#B8DDE3' }} aria-hidden="true"></i></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].entity}</p>
                                                    </div>
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Size</b></p>
                                                        <p><i className="fa fa-signal fa-3x" style={{ color: '#B8DDE3' }} aria-hidden="true"></i></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].size}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {
                                lstuniversity_degree != null && lstuniversity_degree.length > 0 ?
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <div className="card">
                                                    <h2 className="card-header font-weight-bold text-center text-md-left" style={{ backgroundColor: '#B8DDE3', color: 'black' }}>Study Areas And Degree Levels</h2>
                                                    <div className="card-body" id="myGroup_degree">
                                                        {
                                                            lstuniversity_degree.map((item, i) => {
                                                                return (
                                                                    <div className='container p-0'>
                                                                        <div className="row mb-1 border">
                                                                            <div className="col-md-6 text-md-left text-center" style={{ paddingTop: '7px' }}>
                                                                                <i className="fa fa-book" aria-hidden="true"></i> <b>{item.degreeName}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div className="show row mb-1 divparent">
                                                                            <div className="col-12">
                                                                                <div className="card card-body">
                                                                                    <div className="row mt-2 justify-content-center">
                                                                                        <p className="text-center">
                                                                                            {
                                                                                                lstunidegree != null && lstunidegree.length > 0 ?
                                                                                                    lstunidegree[i].map((item, j) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <button disabled className='btn btn-outline-dark mb-1 mr-1' style={{ color: 'black' }}>{item.replace('amp;', '').trim()}</button>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                    : ""
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <br />
                            {
                                academic_structure != null && academic_structure.length > 0 ?
                                    <div className="container">
                                        <div className="card text-center">
                                            <h2 className="card-header font-weight-bold text-center text-md-left" style={{ backgroundColor: '#B8DDE3', color: 'black' }}>Academic Structure</h2>
                                            <div className="card-body">
                                                <button className="btn btn-outline-dark disabled mb-1"><b>{universitydetails[0].uniName}</b></button>
                                                <center>|</center>
                                                <div className="row mt-2 justify-content-center">
                                                    <p>
                                                        {
                                                            academic_structure.map((item, i) => {
                                                                return (
                                                                    <button disabled i={i} className="btn btn-outline-dark mb-1 mr-1" style={{ color: 'black' }}>{item}</button>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h2 className="card-header font-weight-bold text-center text-md-left" style={{ backgroundColor: '#B8DDE3', color: 'black' }}>Size And Profile</h2>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <table className="table table-bordered">
                                                            <tr>
                                                                <th>Control Type</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].controlType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Academic Staff</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].academicStaff}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Student Enrollment</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].studentEnrollment}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Campus Setting</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].campusSetting}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Entity Type</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].entity}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Academic Calendar</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].academicCalendar}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <NavLink to="/forms" className="btn btn1 d-sm-none fixed-bottom d-block btn-block font-weight-bold pt-3" style={{ height: '60px', width: '100%', fontSize: '135%', color: 'white', borderRadius: 0 }}>Request Info</NavLink>
                        </>
                        : <div className="container vh-100">
                            <div className="row justify-content-center centered">
                                <div className="col-12 text-danger text-center">
                                    <h5><Spinner animation="border" size="sm" variant="danger" /> Loading ...</h5>
                                </div>
                            </div>
                        </div>
                    : universitydetailserr == 1 ?
                        // <div className="container vh-100">
                        //     <div className="row justify-content-center centered">
                        //         <div className="col-12 text-danger text-center">
                        //             <h5><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Data is Coming soon</h5>
                        //         </div>
                        //     </div>
                        // </div>
                        <_404 />
                        : <div className="container vh-100">
                            <div className="row justify-content-center centered">
                                <div className="col-12 text-danger text-center">
                                    <h5><i className="fa fa-rss" aria-hidden="true"></i> Check your Internet Connection or Reload the page</h5>
                                </div>
                            </div>
                        </div>
            }
            <hr />
            <Container>
                <Row>
                    <Col className="text-left my-3">
                        <h2 className="p-3" style={{ color: 'black', backgroundColor: '#B8DDE3' }}><strong>Related Schools, Colleges And Universities</strong></h2>
                    </Col>
                </Row>
                <Row id="mainschools" className="justify-content-center">
                    {
                        relateduniversities.length > 0 &&
                        relateduniversities.map((s, k) => {
                            return (
                                s.uniName.includes("School") ?
                                    <Col key={k} xs={12}>
                                        <NavLink onClick={() => setcount(count + 1)} to={'/best-schools-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                            <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                <div className="row no-gutters">
                                                    <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                        <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                    </div>
                                                    <div className="col-7 col-md-6 my-auto">
                                                        <div className="card-body p-1">
                                                            <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                            <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                            <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                        <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </Col >
                                    : s.uniName.includes("College") ?
                                        <Col key={k} xs={12}>
                                            <NavLink onClick={() => setcount(count + 1)} to={'/best-colleges-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                    <div className="row no-gutters">
                                                        <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                            <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-7 col-md-6 my-auto">
                                                            <div className="card-body p-1">
                                                                <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                            <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Col >
                                        : s.uniName.includes("University") &&
                                        <Col key={k} xs={12}>
                                            <NavLink onClick={() => setcount(count + 1)} to={'/best-universities-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                    <div className="row no-gutters">
                                                        <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                            <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-7 col-md-6 my-auto">
                                                            <div className="card-body p-1">
                                                                <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                            <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Col >
                            )
                        })
                    }
                </Row >
            </Container>
            <br /><br />
        </>
    )
}