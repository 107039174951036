import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation, useParams, useNavigate } from "react-router-dom";
import { Base_API_URL } from '../API.js'
import banner from '../Images/mainbanner2.png'
import LoadingBar from "react-top-loading-bar";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import circle from '../Images/circle.png';
import hero from '../Images/hero.png';
import hero2 from '../Images/hero2.png';
import hero3 from '../Images/hero3.png';
import _404 from './_404';
import Select from 'react-select';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Typed from 'react-typed';

export default function DataByAcademicsAndStates() {
    var parameter = useParams();
    var location = useLocation();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(50);
    const [schools, setschools] = useState([]);
    const [schoolserr, setschoolserr] = useState(0);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    const [count, setcount] = useState(0);
    useEffect(() => {
        setschoolserr(0)
        if (parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
            || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
            || parameter.parameter === "district-of-columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
            || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
            || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
            || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
            || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
            || parameter.parameter === "nevada" || parameter.parameter === "new-hampshire" || parameter.parameter === "new-jersey" || parameter.parameter === "new-mexico"
            || parameter.parameter === "new-york" || parameter.parameter === "north-carolina" || parameter.parameter === "north-dakota" || parameter.parameter === "ohio"
            || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode-island"
            || parameter.parameter === "south-carolina" || parameter.parameter === "south-dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
            || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
            || parameter.parameter === "washington" || parameter.parameter === "west-virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming") {
            axios.get(Base_API_URL + "/api/SchoolnDegree/GetUniversityByStates?StateName=" + parameter.parameter.replace(/-/g, ' ').trim() + "&pageNumber=" + pages + "&pageSize=4")
                .then(response => {
                    setschools(pre => [...pre, ...response.data])
                    setlength(response.data.length)
                    setProgress(100)
                    setcount((c) => c + 1)
                })
                .catch(err => {
                    //console.log(err);
                    setProgress(100)
                })
        } else if (parameter.parameter.replace('-institutes', '') === "computer-science" || parameter.parameter.replace('-institutes', '') === "engineering"
            || parameter.parameter.replace('-institutes', '') === "arts" || parameter.parameter.replace('-institutes', '') === "business"
            || parameter.parameter.replace('-institutes', '') === "medicine" || parameter.parameter.replace('-institutes', '') === "economics") {
            axios.get(Base_API_URL + "/api/SchoolnDegree/GetUniversityByAcademic?AcademicName=" + parameter.parameter.replace('-institutes', '').replace(/-/g, ' ').trim() + "&pageNumber=" + pages + "&pageSize=4")
                .then(response => {
                    setschools(pre => [...pre, ...response.data])
                    setlength(response.data.length)
                    setProgress(100)
                    setcount((c) => c + 1)
                })
                .catch(err => {
                    //console.log(err);
                    setProgress(100)
                })
        } else {
            setschoolserr(1)
        }
    }, [pages, location.key]);
    //console.log(schools);
    //console.log(length);

    useEffect(() => {
        if (schools.length > 0) {
            if (length > 0) {
                setpages((c) => c + 1)
            }
        }
    }, [count]);

    let options = []
    for (var i = 0; i < schools.length; i++) {
        options.push({ value: schools[i].uniName, label: schools[i].uniName })
    }

    function handle(name) {
        name.value.includes("School") ?
            navigate('/best-schools-in-usa/' + name.value.trim().replace(/ /g, '-').toLowerCase())
            : name.value.includes("College") ?
                navigate('/best-colleges-in-usa/' + name.value.trim().replace(/ /g, '-').toLowerCase())
                : navigate('/best-universities-in-usa/' + name.value.trim().replace(/ /g, '-').toLowerCase())
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    if (schoolserr == 0) {
        return (
            <>
                <LoadingBar color='#C29A81' height={5} progress={progress} />
                {
                    parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
                        || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
                        || parameter.parameter === "district-of-columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
                        || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
                        || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
                        || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
                        || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
                        || parameter.parameter === "nevada" || parameter.parameter === "new-hampshire" || parameter.parameter === "new-jersey" || parameter.parameter === "new-mexico"
                        || parameter.parameter === "new-york" || parameter.parameter === "north-carolina" || parameter.parameter === "north-dakota" || parameter.parameter === "ohio"
                        || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode-island"
                        || parameter.parameter === "south-carolina" || parameter.parameter === "south-dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
                        || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
                        || parameter.parameter === "washington" || parameter.parameter === "west-virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming" ?
                        <Helmet>
                            <title>Top Schools, Colleges & Universities In {parameter.parameter.replace(/-/g, ' ').trim().replace(/\b./g, function (m) { return m.toUpperCase(); })} | Higher Education in the United States - {year}</title>
                            <meta name='description' content={'Rankings and Reviews of top schools, colleges & universities in ' + parameter.parameter.replace(/-/g, ' ').trim().replace(/\b./g, function (m) { return m.toUpperCase(); }) + ', listed by ranking, foundation year, entity (public/private) and control (non-profit/for-profit) type. Learn more about the Higher Education system in the United States.'} />
                            <link rel="canonical" href={window.location.href} />
                        </Helmet>
                        : <Helmet>
                            <title>Top {parameter.parameter.replace(/-/g, ' ').trim().replace(/\b./g, function (m) { return m.toUpperCase(); })} in the United States | Higher Education in the United States - {year}</title>
                            <meta name='description' content={'Rankings and Reviews of top ' + parameter.parameter.replace(/-/g, ' ').trim().replace(/\b./g, function (m) { return m.toUpperCase(); }) + ' in the United States and its States, listed by ranking, foundation year, entity (public/private) and control (non-profit/for-profit) type. Learn more about the Higher Education system in the United States.'} />
                            <link rel="canonical" href={window.location.href} />
                        </Helmet>
                }
                <div className="d-md-block d-none"><br /><br /><br /></div>
                <Container fluid className="pt-4 pt-md-0" style={{ backgroundColor: '#B8DDE3' }}>
                    <Row className="justify-content-center">
                        <Col xs={12} md={6} className="my-auto">
                            <img effect="blur" className="circle" src={circle} alt="animation" />
                            <div id="bannerDiv" className="mx-auto text-md-left text-center">
                                {
                                    parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
                                        || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
                                        || parameter.parameter === "district-of-columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
                                        || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
                                        || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
                                        || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
                                        || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
                                        || parameter.parameter === "nevada" || parameter.parameter === "new-hampshire" || parameter.parameter === "new-jersey" || parameter.parameter === "new-mexico"
                                        || parameter.parameter === "new-york" || parameter.parameter === "north-carolina" || parameter.parameter === "north-dakota" || parameter.parameter === "ohio"
                                        || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode-island"
                                        || parameter.parameter === "south-carolina" || parameter.parameter === "south-dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
                                        || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
                                        || parameter.parameter === "washington" || parameter.parameter === "west-virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming" ?
                                        <h1 style={{ fontSize: '2rem', textTransform: 'capitalize' }}><p className="font-weight-bold mb-0">Find Best <br/><Typed strings={['Schools', 'Colleges', 'Universities']} typeSpeed={150} backSpeed={150} loop /><br/>In {parameter.parameter.replace(/-/g, ' ').trim()}</p></h1>
                                        : <h1 style={{ fontSize: '2rem', textTransform: 'capitalize' }}><p className="font-weight-bold mb-0">Find Best {parameter.parameter.replace('institutes', '').replace(/-/g, ' ').trim()} <Typed strings={['Schools', 'Colleges', 'Universities']} typeSpeed={150} backSpeed={150} loop /><br/>In USA</p></h1>
                                }
                                <small><NavLink to='/top-50-schools-in-usa/' className='text-dark'>Top 50 Schools in USA</NavLink></small>
                                <br />
                                <small><NavLink to='/top-50-colleges-in-usa/' className='text-dark'>Top 50 Colleges in USA</NavLink></small>
                                <br />
                                <small><NavLink to='/top-50-universities-in-usa/' className='text-dark'>Top 50 Universities in USA</NavLink></small>
                            </div>
                            <img effect="blur" className="hero2" src={hero2} alt="animation" />
                        </Col>
                        <Col xs={12} md={6} className="text-center">
                            <LazyLoadImage effect="blur" src={banner} className="py-3 banner" style={{ width: '60%' }} />
                            <img effect="blur" className="hero" src={hero} alt="animation" />
                        </Col>
                    </Row>
                </Container>
                <br />
                {
                    parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
                        || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
                        || parameter.parameter === "district-of-columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
                        || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
                        || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
                        || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
                        || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
                        || parameter.parameter === "nevada" || parameter.parameter === "new-hampshire" || parameter.parameter === "new-jersey" || parameter.parameter === "new-mexico"
                        || parameter.parameter === "new-york" || parameter.parameter === "north-carolina" || parameter.parameter === "north-dakota" || parameter.parameter === "ohio"
                        || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode-island"
                        || parameter.parameter === "south-carolina" || parameter.parameter === "south-dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
                        || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
                        || parameter.parameter === "washington" || parameter.parameter === "west-virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming" ?
                        <Container>
                            <Breadcrumb>
                                <Breadcrumb.Item><NavLink to='/'>Home</NavLink></Breadcrumb.Item>
                                <Breadcrumb.Item active style={{ textTransform: 'capitalize' }}>Find Best Institutes In {parameter.parameter.replace(/-/g, ' ').trim()}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Container>
                        : <Container>
                            <Breadcrumb>
                                <Breadcrumb.Item><NavLink to='/'>Home</NavLink></Breadcrumb.Item>
                                <Breadcrumb.Item active style={{ textTransform: 'capitalize' }}>Best {parameter.parameter.replace(/-/g, ' ').trim()} In USA</Breadcrumb.Item>
                            </Breadcrumb>
                        </Container>
                }
                <br />
                <Container>
                    <Select options={options} placeholder="Select" onChange={handle} />
                </Container>
                <br />
                <Container>
                    <Row id="mainschools" className="justify-content-center">
                        {
                            schools.length > 0 &&
                            schools.map((s, k) => {
                                return (
                                    s.uniName.includes("School") ?
                                        <Col key={k} xs={12}>
                                            <NavLink to={'/best-schools-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                    <div className="row no-gutters">
                                                        <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                            <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-7 col-md-6 my-auto">
                                                            <div className="card-body p-1">
                                                                <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                            <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Col >
                                        : s.uniName.includes("College") ?
                                            <Col key={k} xs={12}>
                                                <NavLink to={'/best-colleges-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                    <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                        <div className="row no-gutters">
                                                            <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                                <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                            </div>
                                                            <div className="col-7 col-md-6 my-auto">
                                                                <div className="card-body p-1">
                                                                    <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                    <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                    <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                                <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </Col >
                                            : <Col key={k} xs={12}>
                                                <NavLink to={'/best-universities-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                    <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                        <div className="row no-gutters">
                                                            <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                                <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                            </div>
                                                            <div className="col-7 col-md-6 my-auto">
                                                                <div className="card-body p-1">
                                                                    <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                    <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                    <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                                <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </Col >
                                )
                            })
                        }
                    </Row >
                </Container>
                <br />
            </>
        )
    } else {
        return (
            <_404 />
        )
    }
}