import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Base_API_URL } from '../API.js'
import banner from '../Images/mainbanner2.png'
import LoadingBar from "react-top-loading-bar";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import circle from '../Images/circle.png';
import hero from '../Images/hero.png';
import hero2 from '../Images/hero2.png';
import hero3 from '../Images/hero3.png';
import com_sci from '../Images/com_sci.jpeg'
import engi from '../Images/engi.jpeg';
import art from '../Images/art.jpeg';
import busi from '../Images/busi.jpeg';
import med from '../Images/med.jpeg';
import eco from '../Images/eco.jpeg';
import Select from 'react-select';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Universities() {
    var location = useLocation();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(50);
    const [schools, setschools] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    const [count, setcount] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/SchoolnDegree/GetSchoolCollegeUniversitiesData?Name=universities&pageNumber=" + pages + "&pageSize=4")
            .then(response => {
                setschools(pre => [...pre, ...response.data])
                setlength(response.data.length)
                setProgress(100)
                setcount((c) => c + 1)
            })
            .catch(err => {
                //console.log(err);
            })
    }, [pages, location.key]);
    //console.log(schools);
    //console.log(length);

    useEffect(() => {
        if (schools.length > 0) {
            if (length > 0) {
                setpages((c) => c + 1)
            }
        }
    }, [count]);

    const [states, setstates] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
            })
            .catch(err => {
                //console.log(err);
            })
    }, []);

    let options = []
    for (var i = 0; i < schools.length; i++) {
        options.push({ value: schools[i].uniName, label: schools[i].uniName })
    }

    function handle(name){
        navigate('/best-universities-in-usa/' + name.value.trim().replace(/ /g, '-').toLowerCase())
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='#C29A81' height={5} progress={progress} />
            <Helmet>
                <title>Top Universities in the United States | Higher Education in the United States - {year}</title>
                <meta name='description' content='Rankings and Reviews of  Universities in the United States and its States, listed by ranking, foundation year, entity (public/private) and control (non-profit/for-profit) type. Learn more about the Higher Education system in the United States.' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="d-md-block d-none"><br /><br /><br /></div>
            <Container fluid className="pt-4 pt-md-0" style={{ backgroundColor: '#B8DDE3' }}>
                <Row className="justify-content-center">
                    <Col xs={12} md={6} className="my-auto">
                        <img effect="blur" className="circle" src={circle} alt="animation" />
                        <div id="bannerDiv" className="mx-auto text-md-left text-center">
                            <h1 style={{ fontSize: '2.9rem' }}><p className="font-weight-bold mb-0">Find Best Universities In USA</p></h1>
                            <small><NavLink to='/top-50-schools-in-usa/' className='text-dark'>Top 50 Schools in USA</NavLink></small>
                            <br />
                            <small><NavLink to='/top-50-colleges-in-usa/' className='text-dark'>Top 50 Colleges in USA</NavLink></small>
                            <br />
                            <small><NavLink to='/top-50-universities-in-usa/' className='text-dark'>Top 50 Universities in USA</NavLink></small>
                        </div>
                        <img effect="blur" className="hero2" src={hero2} alt="animation" />
                    </Col>
                    <Col xs={12} md={6} className="text-center">
                        <LazyLoadImage effect="blur" src={banner} className="py-3 banner" style={{ width: '60%' }} />
                        <img effect="blur" className="hero" src={hero} alt="animation" />
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{ backgroundColor: 'whitesmoke' }}>
                <Container className="py-5">
                    <Row>
                        <Col className="text-left mb-4" style={{ color: 'black' }}>
                            <h2><strong className="">Search by Academics</strong></h2>
                        </Col>
                    </Row>
                    <Row id="mainAcad">
                        <Col md={4} className="mb-4">
                            <NavLink to="/computer-science-institutes/">
                                <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                    <div className="category__icon" style={{ width: '10%' }}>
                                        <LazyLoadImage effect="blur" src={com_sci} className="w-100 rounded-circle" style={{ height: '34px' }} />
                                    </div> &nbsp;&nbsp;
                                    <div className="category__content">
                                        <h4 className="category__title">
                                            Computer Science
                                        </h4>
                                        <p>Top Computer Science Institutes</p>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col md={4} className="mb-4">
                            <NavLink to="/engineering-institutes/">
                                <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                    <div className="category__icon" style={{ width: '10%' }}>
                                        <LazyLoadImage effect="blur" src={engi} className="w-100 rounded-circle" style={{ height: '34px' }} />
                                    </div> &nbsp;&nbsp;
                                    <div className="category__content">
                                        <h4 className="category__title">
                                            Engineering
                                        </h4>
                                        <p>Top Engineering Institutes</p>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col md={4} className="mb-4">
                            <NavLink to="/arts-institutes/">
                                <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                    <div className="category__icon" style={{ width: '10%' }}>
                                        <LazyLoadImage effect="blur" src={art} className="w-100 rounded-circle" style={{ height: '34px' }} />
                                    </div> &nbsp;&nbsp;
                                    <div className="category__content">
                                        <h4 className="category__title">
                                            Arts & Design
                                        </h4>
                                        <p>Top Arts & Design Institutes</p>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col md={4} className="mb-4">
                            <NavLink to="/business-institutes/">
                                <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                    <div className="category__icon" style={{ width: '10%' }}>
                                        <LazyLoadImage effect="blur" src={busi} className="w-100 rounded-circle" style={{ height: '34px' }} />
                                    </div> &nbsp;&nbsp;
                                    <div className="category__content">
                                        <h4 className="category__title">
                                            Business
                                        </h4>
                                        <p>Top Business Institutes</p>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col md={4} className="mb-4">
                            <NavLink to="/medicine-institutes/">
                                <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                    <div className="category__icon" style={{ width: '10%' }}>
                                        <LazyLoadImage effect="blur" src={med} className="w-100 rounded-circle" style={{ height: '34px' }} />
                                    </div> &nbsp;&nbsp;
                                    <div className="category__content">
                                        <h4 className="category__title">
                                            Medicine
                                        </h4>
                                        <p>Top Medicine Institutes</p>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                        <Col md={4} className="mb-4">
                            <NavLink to="/economics-institutes/">
                                <div className="category__item mb-30 transition-3 d-flex align-items-center">
                                    <div className="category__icon" style={{ width: '10%' }}>
                                        <LazyLoadImage effect="blur" src={eco} className="w-100 rounded-circle" style={{ height: '34px' }} />
                                    </div> &nbsp;&nbsp;
                                    <div className="category__content">
                                        <h4 className="category__title">
                                            Economics
                                        </h4>
                                        <p>Top Economics Institutes</p>
                                    </div>
                                </div>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <br />
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item><NavLink to='/'>Home</NavLink></Breadcrumb.Item>
                    <Breadcrumb.Item active>Best Universities In USA</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <br />
            <Container>
                <Select options={options} placeholder="Select University" onChange={handle} />
            </Container>
            <br />
            <Container>
                <Row id="mainschools" className="justify-content-center">
                    {
                        schools.length > 0 &&
                        schools.map((s, k) => {
                            return (
                                s.uniName.includes("School") ?
                                    <Col key={k} xs={12}>
                                        <NavLink to={'/best-schools-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                            <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                <div className="row no-gutters">
                                                    <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                        <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                    </div>
                                                    <div className="col-7 col-md-6 my-auto">
                                                        <div className="card-body p-1">
                                                            <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                            <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                            <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                        <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </Col >
                                    : s.uniName.includes("College") ?
                                        <Col key={k} xs={12}>
                                            <NavLink to={'/best-colleges-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                    <div className="row no-gutters">
                                                        <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                            <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-7 col-md-6 my-auto">
                                                            <div className="card-body p-1">
                                                                <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                            <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Col >
                                        : <Col key={k} xs={12}>
                                            <NavLink to={'/best-universities-in-usa/' + s.uniName.trim().replace(/ /g, '-').toLowerCase()}>
                                                <div className="card my-2 py-2 box border-right-0 border-left-0" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                    <div className="row no-gutters">
                                                        <div className="col-5 col-md-2 my-auto mx-auto ml-md-0 text-center">
                                                            <LazyLoadImage effect="blur" src={s.image} alt={s.uniName} className="" style={{ width: '70px', cursor: 'pointer', borderRadius: '50px' }} />
                                                        </div>
                                                        <div className="col-7 col-md-6 my-auto">
                                                            <div className="card-body p-1">
                                                                <h5 className="card-title mb-0 font-weight-bold" style={{ fontSize: '95%', textDecoration: 'none', color: 'cornflowerblue' }}>{s.uniName}</h5>
                                                                <p className="card-text mb-0" style={{ fontSize: '15px' }}><b>Rank: </b><small className="text-muted">{s.rank}</small></p>
                                                                <p className="card-text"><i className="fa fa-map-marker" style={{ color: 'red' }} aria-hidden="true"></i><small className="text-muted"> {s.address}</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mt-auto mb-auto text-center">
                                                            <NavLink to="/forms" className="btn btn1 font-weight-bold" style={{ width: '50%', borderRadius: '', color: 'white' }}>Connect Now</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Col >
                            )
                        })
                    }
                </Row >
            </Container>
            <br />
        </>
    )
}