import React from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import logo from '../Images/logo.png'

export default function Header() {
    function closeNav() {
        document.getElementById("navbarSupportedContent").classList.remove("show");
    }

    window.addEventListener('scroll', function() {
        var a = document.documentElement.scrollTop;
        if (a > 0){
            if (document.getElementById("mainNavMob") != null) {
                document.getElementById("mainNavMob").style.backgroundColor = 'white';
                document.getElementById("mainNavMob").style.boxShadow = '0 0 15px 0 rgba(0,0,0,0.4)'
            }
        } else {
            if (document.getElementById("mainNavMob") != null) {
                document.getElementById("mainNavMob").style.backgroundColor = '#B8DDE3'
                document.getElementById("mainNavMob").style.boxShadow = ''
            }
        }
    })

    return (
        <header>
            {/* For PC and Laptop screens start */}
            <Navbar fixed="top" expand="lg" id="mainNavMob" className="bg-body-tertiary d-md-block d-none" style={{ backgroundColor: '#B8DDE3' }}>
                <Container fluid>
                    <NavLink onClick={closeNav} className="navbar-brand text-md-center text-left p-0" to="/">
                        <img effect="blur" src={logo} style={{ width: '20%' }} />
                        <sub className="" style={{ fontSize: '100%', color: 'black' }}><strong> SchoolnDegree</strong></sub>
                    </NavLink>
                    <Navbar.Collapse>
                        <Nav className="mx-auto" id="links1">
                            <li className="nav-item active">
                                <NavLink to="/best-schools-in-usa/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman' }}><i className="fa fa-graduation-cap"></i> Schools <span className="sr-only">(current)</span></NavLink>
                            </li>
                            <li className="nav-item active">
                                <NavLink to="/best-colleges-in-usa/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman' }}><i className="fa fa-graduation-cap"></i> Colleges <span className="sr-only">(current)</span></NavLink>
                            </li>
                            <li className="nav-item active">
                                <NavLink to="/best-universities-in-usa/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman' }}><i className="fa fa-graduation-cap"></i> Universities <span className="sr-only">(current)</span></NavLink>
                            </li>
                            {/* <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Ranking
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/top-50-universities-in-usa/">Top 50 Universities in USA</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/top-100-universities-in-usa/">Top 100 Universities in USA</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/top-200-universities-in-usa/">Top 200 Universities in USA</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/top-500-universities-in-usa/">Top 500 Universities in USA</NavLink></li>
                                </ul>
                            </li> */}
                            {/* <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Type
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink to="/public/" className="dropdown-item">Public</NavLink></li>
                                    <li><NavLink to="/private/" className="dropdown-item">Private</NavLink></li>
                                    <li><NavLink to="/public-state/" className="dropdown-item">Public State</NavLink></li>
                                    <li><NavLink to="/public-federal/" className="dropdown-item">Public Federal</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Academic
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/4-1-4/">4-1-4</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/other/">Other</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/quarters/">Quarters</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/semesters/">Semesters</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/trimesters/">Trimesters</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Campus
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink className="dropdown-item" to="/not-reported/">Not Reported</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/urban/">Urban</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/suburban/">Sub-Urban</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/rural/">Rural</NavLink></li>
                                </ul>
                            </li> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* For PC and Laptop screens end */}

            {/* For Mobile screens start */}
            <Navbar expand="lg" className="bg-body-tertiary d-md-none d-block" style={{ backgroundColor: '#B8DDE3' }}>
                <Container fluid>
                    <NavLink to="/" className="navbar-brand text-md-center text-left p-0">
                        <img effect="blur" src={logo} style={{ width: '15%' }} />
                        <sub className="font-weight-bold" style={{ fontSize: '80%' }}><strong> SchoolnDegree</strong></sub>
                    </NavLink>
                    <Navbar.Toggle className="ml-auto bg-light" aria-controls="navbarSupportedContent" />
                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav className="mx-auto" id="links2">
                            <li className="nav-item active border-0 text-center">
                                <img effect="blur" src={logo} style={{ width: '25%' }} />
                                <button className="close" data-dismiss="navbar-collapse" onClick={closeNav} aria-expanded="false" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </li>
                            <li className="nav-item active">
                                <NavLink onClick={closeNav} to="/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman', color: 'white' }}><i className="fa fa-home"></i> Home <span className="sr-only">(current)</span></NavLink>
                            </li>
                            <li className="nav-item active">
                                <NavLink onClick={closeNav} to="/best-schools-in-usa/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman', color: 'white' }}><i className="fa fa-graduation-cap"></i> Schools <span className="sr-only">(current)</span></NavLink>
                            </li>
                            <li className="nav-item active">
                                <NavLink onClick={closeNav} to="/best-colleges-in-usa/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman' }}><i className="fa fa-graduation-cap"></i> Colleges <span className="sr-only">(current)</span></NavLink>
                            </li>
                            <li className="nav-item active">
                                <NavLink onClick={closeNav} to="/best-universities-in-usa/" className="nav-link font-weight-bold" style={{ fontFamily: 'Times New Roman' }}><i className="fa fa-graduation-cap"></i> Universities <span className="sr-only">(current)</span></NavLink>
                            </li>
                            {/* <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Ranking
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/top-50-universities-in-usa/">Top 50 Universities in USA</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/top-100-universities-in-usa/">Top 100 Universities in USA</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/top-200-universities-in-usa/">Top 200 Universities in USA</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/top-500-universities-in-usa/">Top 500 Universities in USA</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Type
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink onClick={closeNav} to="/public/" className="dropdown-item">Public</NavLink></li>
                                    <li><NavLink onClick={closeNav} to="/private/" className="dropdown-item">Private</NavLink></li>
                                    <li><NavLink onClick={closeNav} to="/public-state/" className="dropdown-item">Public State</NavLink></li>
                                    <li><NavLink onClick={closeNav} to="/public-federal/" className="dropdown-item">Public Federal</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Academic
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/4-1-4/">4-1-4</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/other/">Other</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/quarters/">Quarters</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/semesters/">Semesters</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/trimesters/">Trimesters</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> University Campus
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/not-reported/">Not Reported</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/urban/">Urban</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/suburban/">Sub-Urban</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/rural/">Rural</NavLink></li>
                                </ul>
                            </li> 
                            <li className="nav-item dropdown active">
                                <NavLink className="nav-link dropdown-toggle font-weight-bold text-white" style={{ fontFamily: 'Times New Roman' }} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-university" aria-hidden="true"></i> Degrees by Subjects
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Sciences College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Business College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Nursing College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Liberal College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Arts College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Social Work School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Education College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Health Professions College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Humanities College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-colleges-of-science-in-usa/">Fine Arts College</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Computer Science School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Engineering School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Media School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Architecture School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Human Services School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Law School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Hospitality Management School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Criminal Justice School</NavLink></li>
                                    <li><NavLink onClick={closeNav} className="dropdown-item" to="/degree/top-school-of-science-in-usa/">Information Technology School</NavLink></li>
                                </ul>
                            </li> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* For Mobile screens end */}
        </header>
    )
}