import React from 'react'
import '../App.css'
import { NavLink } from "react-router-dom";

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear().toString();

    let top = {
        display: 'none',
        position: 'fixed',
        bottom: '70px',
        right: '30px',
        zIndex: '99',
        fontSize: '18px',
        backgroundColor: '#B8DDE3',
        color: 'black',
        cursor: 'pointer',
        padding: '15px 20px 15px 20px',
        borderRadius: '50px',
    }

    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
        if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
            if (document.getElementById("top") != null) {
                document.getElementById("top").style.display = "block";
            }
        } else {
            if (document.getElementById("top") != null) {
                document.getElementById("top").style.display = "none";
            }
        }
    }

    function scrollTop() {
        window.scrollTo(0, 0)
    }

    return (
        <>
            <NavLink id='top' onClick={scrollTop} title="Go To Top" style={top}><i className="fa fa-arrow-up" aria-hidden="true"></i></NavLink>
            <footer className="border-top footer text-muted">
                <div className="container-fluid text-center text-light font-weight-bold">
                    &copy; CopyRights {year}, All Rights Reserved
                </div>
            </footer>
        </>
    )
}
