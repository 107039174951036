import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Index from './Components/Index';
import Schools from './Components/Schools';
import Colleges from './Components/Colleges';
import Universities from './Components/Universities';
import Top50Schools from './Components/Top50Schools';
import Top50Colleges from './Components/Top50Colleges';
import Top50Universities from './Components/Top50Universities';
import DataByAcademicsAndStates from './Components/DataByAcademicsAndStates';
import SchoolCollegeUniversityDetails from './Components/SchoolCollegeUniversityDetails'
import Forms from './Components/Form';
import _404 from './Components/_404';
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path='*' element={<_404 />} />
        <Route path='/best-schools-in-usa/' element={<Schools />} />
        <Route path='/best-colleges-in-usa/' element={<Colleges />} />
        <Route path='/best-universities-in-usa/' element={<Universities />} />
        <Route path='/top-50-schools-in-usa/' element={<Top50Schools />} />
        <Route path='/top-50-colleges-in-usa/' element={<Top50Colleges />} />
        <Route path='/top-50-universities-in-usa/' element={<Top50Universities />} />
        <Route path='/:parameter/' element={<DataByAcademicsAndStates />} />
        <Route path='/:parameter1/:parameter2' element={<SchoolCollegeUniversityDetails />} />
        <Route path='/forms' element={<Forms />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
