import React from "react";
import { Helmet } from "react-helmet";

export default function _404() {
    return (
        <>
            <Helmet>
                <title>Page Not Found</title>
                <meta name='description' content={'Page Not Found'} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <h1 className="centered">Page Not Found</h1>
        </>
    )
}